var render_results;

document.addEventListener('click', function(e) {
  var button, parent;
  if ((button = e.target.closest('.panel-toggle')) && (parent = e.target.closest('#panel'))) {
    if (parent.classList.contains('expand')) {
      parent.classList.remove('expand');
    } else {
      parent.classList.add('expand');
    }
  }
  if ((button = e.target.closest('.menu-toggle')) && (parent = e.target.closest('#panel'))) {
    if (parent.classList.contains('user')) {
      parent.classList.remove('user');
    } else {
      parent.classList.add('user');
    }
  }
  if (e.target.closest('.search')) {
    e.target.closest('#panel').classList.add('searching');
  }
  if (!e.target.closest('.menu-search')) {
    return document.getElementById("panel").classList.remove('searching');
  }
});

document.addEventListener("keyup", function(e) {
  var csrf, input;
  if (e.target.id === "search") {
    input = e.target;
    if (input.value.length > 2) {
      csrf = document.querySelector("[name='csrf-token']").content;
      return fetch("/a/s.json", {
        method: 'POST',
        headers: {
          "X-CSRF-Token": csrf,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          query: input.value
        })
      }).then(function(resp) {
        return resp.json();
      }).then(function(data) {
        console.log(data);
        return render_results(data);
      });
    }
  }
});

render_results = function(data) {
  var a, container, d, i, item, len, r, ref, results;
  container = document.getElementById("results");
  container.innerHTML = "";
  ref = data["data"];
  results = [];
  for (i = 0, len = ref.length; i < len; i++) {
    item = ref[i];
    r = document.createElement("div");
    r.setAttribute('class', `result ${item["attributes"]["color"]}`);
    a = document.createElement("a");
    a.setAttribute('href', item["attributes"]["path"]);
    a.innerHTML = item["attributes"]["title"];
    d = document.createElement("div");
    d.innerHTML = item["attributes"]["preview_html"];
    r.appendChild(a);
    r.appendChild(d);
    results.push(container.appendChild(r));
  }
  return results;
};
