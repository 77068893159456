var Try;

import React from "react";

import PropTypes from "prop-types";

Try = class Try extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return React.createElement(React.Fragment, null,
      React.createElement("h1", null, "HELLO FRANK")
    );
  }

};

export default Try;
