var autoExpand;

document.addEventListener('input', function(e) {
  // autosize textareas
  if (e.target.tagName.toLowerCase() !== 'textarea') {
    return;
  }
  return autoExpand(e.target);
});

document.addEventListener('turbolinks:load', function(e) {
  var i, len, ref, results, ta;
  ref = document.getElementsByTagName("textarea");
  results = [];
  for (i = 0, len = ref.length; i < len; i++) {
    ta = ref[i];
    results.push(setTimeout(function() {
      return autoExpand(ta);
    }, 200));
  }
  return results;
});

autoExpand = function(field) {
  var computed, height;
  field.style.height = 'inherit';
  computed = window.getComputedStyle(field);
  height = parseInt(computed.getPropertyValue('border-top-width'), 10) + parseInt(computed.getPropertyValue('padding-top'), 10) + field.scrollHeight + parseInt(computed.getPropertyValue('padding-bottom'), 10) + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
  return field.style.height = height + 'px';
};
